
.header-row .modal-title {
  display: block;
  width: 100%;
}

.table th {
  vertical-align: middle;
}

.chat-active {
  color: #495057;
  background-color: #e9ecef;
}


.chat-online {
  color: #34ce57
}

.chat-offline {
  color: #e4606d
}

.chat-messages {
  display: flex;
  flex-direction: column-reverse !important;
  overflow-y: scroll;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.chat-message-left, .chat-message-right {
  display: flex;
  flex-shrink: 0
}

.chat-message-left {
  margin-right: auto
}

.chat-message-right {
  flex-direction: row-reverse;
  margin-left: auto
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.flex-full {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.content {
  display: flex;
  flex-direction: column;
}

.container-fluid.p-0 {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.no-gutters.row {
  flex: 1;
}

.brand-image {
  height: 40px;
  -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
  filter: invert(100%);
}
